import React from 'react';
import './home.scss';
import PieChart, {
  Legend,
  Series,
  Tooltip,
  Format,
  Label,
  Connector,
  Export,
} from 'devextreme-react/pie-chart';
import { populationByRegions } from './data.js';

export default function Home() {

function customizeTooltip(arg: { valueText: string; percent: number; }) {
  return {
    text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
  };
}


  return (
    <PieChart
      id="pie"
      type="doughnut"
      title="Number of ShareHoleder Per Regions"
      palette="Soft Pastel"
      dataSource={populationByRegions}
    >
      <Series argumentField="region">
        <Label visible={true} format="millions">
          <Connector visible={true} />
        </Label>
      </Series>
      <Export enabled={true} />
      <Legend margin={0} horizontalAlignment="right" verticalAlignment="top" />
      <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
        <Format type="millions" />
      </Tooltip>
    </PieChart>
  );
}
