import React, { useState, useCallback } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './profile.scss';


import Form, { 
  SimpleItem, 
  GroupItem,
  ButtonItem,
  TabbedItem,
  Tab,
  TabPanelOptions,
  NumericRule, 
  EmailRule,
  ButtonOptions
} from 'devextreme-react/form';
import serverUrl from '../../url'
import axios from 'axios'; // Import axios for making HTTP requests

const initialMemberData = {
  የአባላት_ሙሉ_ስም: '',
  ስልክ: '',
  ዜግነት: '',
  ክልል: '',
  ከተማ: '',
  ወረዳ: null,
  ቀበሌ: '',
  አክ_ድርሻ: '',
  ሸርብዛት: '',
  ወ_መ: '',
  ሌላ_ስልክ: '',
  ተወካይ: '',
  የተከፈለ: '',
  ምርመራ: ''
};

const Test = () => {
  const [formData, setFormData] = useState(initialMemberData);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    
    // Make POST request to backend endpoint
    axios.post(`${serverUrl}members/create`, formData)
      .then(response => {
        console.log('Form data sent successfully:', response.data);
        // Optionally handle success
        alert('Form data submitted successfully!');
      })
      .catch(error => {
        console.error('Error submitting form data:', error);
        // Optionally handle error
        alert('Error submitting form data. Please try again.');
      });
  }, [formData]);

  const handleFieldChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  return (
    <div className="card-container">
         
      <form  onSubmit={handleSubmit} >
        <Form
          id="form"
          formData={formData}
          labelLocation="top"
          showColonAfterLabel={false}
        >
          <GroupItem colCount={2}>
            <GroupItem caption="New Member">
              <SimpleItem 
                dataField="የአባላት_ሙሉ_ስም" 
                isRequired={true} 
                editorOptions={{ placeholder: 'Enter የአባላት_ሙሉ_ስም' }}
                onChange={handleFieldChange}
              />
              <SimpleItem 
                dataField="ስልክ" 
                editorOptions={{ placeholder: 'Enter ስልክ' }}
                onChange={handleFieldChange}
              />
        
      
       
      <SimpleItem 
        dataField="ክልል" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ክልል' }} 
      />
         
       
         <SimpleItem 
        dataField="ዜግነት" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ዜግነት' }} 
      />
       <SimpleItem 
        dataField="ከተማ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ከተማ' }} 
      />
      <SimpleItem 
        dataField="ወረዳ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ወረዳ' }} 
      />
        
      
            </GroupItem>
            <GroupItem caption="Personal Information">
      <TabbedItem>
        <TabPanelOptions height={260} />
        <Tab title="Contacts">
          {/* <SimpleItem 
            dataField="phone" 
            label={{ visible: false }} 
            editorOptions={{ placeholder: 'Enter phone number' }} 
          /> */}
           <SimpleItem 
        dataField="አክ_ድርሻ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter አክ_ድርሻ' }} 
      />
      <SimpleItem 
        dataField="ሸርብዛት" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ሸርብዛት' }} 
      />
         
       
         <SimpleItem 
        dataField="ወ_መ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ወ_መ' }} 
      />
       <SimpleItem 
        dataField="ሌላ_ስልክ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ሌላ_ስልክ' }} 
      />
      <SimpleItem 
        dataField="ተወካይ" 
        label={{ visible: false }} 
        editorOptions={{ placeholder: 'Enter ተወካይ' }} 
      />
        </Tab></TabbedItem></GroupItem>
          </GroupItem>
          <ButtonItem horizontalAlignment="center">
            <ButtonOptions 
              text="Submit the Form"
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
   </div>
  );
}

export default Test;
