import React, { useEffect,navigate, useState } from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,

} from 'devextreme-react/data-grid';
import axios from 'axios';
import serverUrl from '../../url'
import { useNavigate} from 'react-router-dom';

const Member2 = () => {
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate=useNavigate()

  useEffect(() => {
    axios.get(`${serverUrl}members`)
      .then(res => {
        console.log(res.data);
        setMemberList(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching member data:', error);
        setLoading(false);
      });
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const phoneFilterValue = React.useRef(null);

  const phoneFilter = (value, filterText) => {
    // Custom filtering logic for phone number
    if (!filterText) return true; // If filter text is empty, show all rows

    const filterParts = filterText.split('-'); // Split filter text by '-'
    const phoneParts = value.split('-'); // Split phone number value by '-'

    for (let i = 0; i < filterParts.length; i++) {
      if (!phoneParts[i] || !phoneParts[i].startsWith(filterParts[i])) {
        return false; // If any part doesn't match, filter out the row
      }
    }

    return true; // All parts match, keep the row
  };


  const onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (['የአባላት_ሙሉ_ስም', 'ስልክ','ወ_መ', 'አክ_ድርሻ','ዜግነት', 'ከተማ'].includes(e.column.dataField)) {
        e.cellElement.style.pointerEvents = 'none';
        e.cellElement.style.backgroundColor = '#f5f5f5';
      }
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>SHAREHOLDERS</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={memberList}
        showBorders={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        keyExpr={'id'}  // Specify the key field
        onCellPrepared={onCellPrepared} // Add this line to handle cell preparation
  
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column dataField={'id'} width={90} hidingPriority={2}      />
        <Column
          dataField={'የአባላት_ሙሉ_ስም'}
          width={190}
          caption={'የአባላት_ሙሉ_ስም'}
          hidingPriority={8}
              
        />
        <Column
          dataField={'ስልክ'}
          caption={'ስልክ'}
          hidingPriority={2}
              
        />

        <Column
          dataField={'ዜግነት'}
          caption={'ዜግነት'}
          hidingPriority={7}
            
        />
        <Column
          dataField={'ክልል'}
          caption={'ክልል'}
          hidingPriority={6}
            
        />
        <Column
          dataField={'ከተማ'}
          caption={'ከተማ'}
          hidingPriority={5}
            
        />
        <Column
          dataField={'ወረዳ'}
          caption={'ወረዳ'}
          allowSorting={false}
          hidingPriority={4}
            
        />
        <Column
          dataField={'ቀበሌ'}
          caption={'ቀበሌ'}
          hidingPriority={3}
            
        />
        <Column
          dataField={'አክ_ድርሻ'}
          caption={'አክ_ድርሻ'}
          hidingPriority={2}
            
        />
        <Column
          dataField={'ሸርብዛት'}
          caption={'ሸርብዛት'}
          hidingPriority={1}
            
        />
        <Column
          dataField={'ወ_መ'}
          caption={'ወ_መ'}
          hidingPriority={0}
            
        />
        <Column
          dataField={'ሌላ_ስልክ'}
          caption={'ሌላ_ስልክ'}
          hidingPriority={2}
            
        />
        <Column
          dataField={'ተወካይ'}
          caption={'ተወካይ'}
          hidingPriority={2}
            
        />
        <Column
          dataField={'የተከፈለ'}
          caption={'የተከፈለ'}
          hidingPriority={2}
            
        />
        <Column
          dataField={'ምርመራ'}
          caption={'ምርመራ'}
          hidingPriority={2}
            
        />

<Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={false}
          useIcons={true}
        />

        {/* Custom column for Edit button */}
        {/* <Column
          type="buttons"
          width={100}
          buttons={[{
            hint: 'Edit',
            icon: 'edit',
         //   onClick: (e) => handleEdit(e.data)
         onClick: ({ row }) => {
          // Redirect to the edit page with the member id
          window.location = `/members#/edit/${row.data.id}`;
        }
      
          }]}/> */}
<Column 
          caption="Edit" 
          cellRender={({ data }) => (
            <button onClick={() => handleEdit(data.id)}>Edit</button>
          )}
        />
      </DataGrid>
    </React.Fragment>
  );
};

export default Member2;
