export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Examples',
    icon: 'folder',
    items: [
      // {
      //   text: 'Profile',
      //   path: '/profile'
      // },
      // {
      //   text: 'Tasks',
      //   path: '/tasks'
      // },
      {
        text: 'Form',
        path: '/tests'
      },
      
    ]
  },
  {
    icon:"folder",
    text:"ShareHolder",
    items:[

      // {
      //   text:"shareholder1",
      //   path:"/members"
      // },
      {
      text:"shareholder1",
        path:"/members"
      },
      {
          text:"shareholder2",
        path:"/members2"
      }
    ]
  
  },
  {
 text:"create account",
        path:"/create-account"
  }
  ];
