import React, { useEffect, useState } from 'react';
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  Export // Import Export component
} from 'devextreme-react/data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'; // Import Excel exporter
import { Workbook } from 'exceljs'; // Import Workbook from exceljs
import serverUrl from '../../url'
const Member2 = () => {
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${serverUrl}members`)
      .then(res => {
        console.log(res.data);
        setMemberList(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching member data:', error);
        setLoading(false);
      });
  }, []);

  const onExporting = (e) => {
    console.log('Exporting triggered');
    e.cancel = true; // Cancel default export

    const workbook = new Workbook();
    console.log('Workbook created');
    const worksheet = workbook.addWorksheet('Members'); // Create a worksheet
    console.log('Worksheet created');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet, // Provide the worksheet object
      customizeExcelCell: ({ excelCell, gridCell }) => {
        const { rowType, column } = gridCell;

        if (rowType === 'data') {
          if (column.dataField === 'id') {
            excelCell.value = Number(excelCell.value); // Ensure ID is treated as number
          }
        }
      }
    }).then((data) => {
      console.log('Data exported successfully:', data);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Members.xlsx');
        console.log('File saved successfully');
      });
    }).catch(error => {
      console.error('Error exporting data:', error);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>SHAREHOLDERS</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={memberList}
        showBorders={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        keyExpr={'id'}
        onExporting={onExporting}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Export enabled={true} /> {/* Enable export button */}
        
        {/* Columns definition */}
        <Column dataField={'id'} width={90} />
        <Column dataField={'የአባላት_ሙሉ_ስም'} caption={'የአባላት_ሙሉ_ስም'} width={190} />
        <Column dataField={'ስልክ'} caption={'ስልክ'} />
        <Column dataField={'ዜግነት'} caption={'ዜግነት'} />
        <Column dataField={'ክልል'} caption={'ክልል'} />
        <Column dataField={'ከተማ'} caption={'ከተማ'} />
        <Column dataField={'ወረዳ'} caption={'ወረዳ'} />
        <Column dataField={'ቀበሌ'} caption={'ቀበሌ'} />
        <Column dataField={'አክ_ድርሻ'} caption={'አክ_ድርሻ'} />
        <Column dataField={'ሸርብዛት'} caption={'ሸርብዛት'} />
        <Column dataField={'ወ_መ'} caption={'ወ_መ'} />
        <Column dataField={'ሌላ_ስልክ'} caption={'ሌላ_ስልክ'} />
        <Column dataField={'ተወካይ'} caption={'ተወካይ'} />
        <Column dataField={'የተከፈለ'} caption={'የተከፈለ'} />
        <Column dataField={'ምርመራ'} caption={'ምርመራ'} />
      </DataGrid>
    </React.Fragment>
  );
};

export default Member2;
