import { HomePage, TasksPage, EditMemberPage,ProfilePage,TestPage, MemberPage,MemberPage1, MemberPage2 } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
path:'/tests',
element:TestPage
    },
    {
        path:'/members',
        element:MemberPage
            },
            {
                path:'/members1',
                element:MemberPage1
                    },
                    {
                        path:'/members2',
                        element:MemberPage2
            },
            {
                path: '/edit/:id',
                element: EditMemberPage
            }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
