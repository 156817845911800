import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow
} from 'devextreme-react/data-grid';
import axios from 'axios';
import serverUrl from '../../url'
const Member1 = () => {
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://liqatradingandmanufacturing.com/api/data')
      .then(res => {
        console.log(res.data);
        setMemberList(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching member data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Members</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={memberList}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column dataField={'id'} width={90} hidingPriority={2} />
        <Column
          dataField={'name'}
          width={190}
          caption={'Name'}
          hidingPriority={8}
        />
        <Column
          dataField={'address'}
          caption={'Address'}
          hidingPriority={6}
        />
        <Column
          dataField={'gender'}
          caption={'Gender'}
          hidingPriority={5}
        />
        <Column
          dataField={'occupation'}
          caption={'Occupation'}
          hidingPriority={7}
        />
        <Column
          dataField={'phone'}
          caption={'Phone'}
          hidingPriority={3}
        />
      </DataGrid>
    </React.Fragment>
  );
};

export default Member1;
