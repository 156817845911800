import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import axios from 'axios';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import serverUrl from '../url'

const AuthContext = createContext();

function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserProfile = useCallback(async (token) => {
    try {
      const response = await axios.get(`${serverUrl}profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { isOk: true, data: response.data };
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return { isOk: false, message: 'Failed to fetch user profile' };
    }
  }, []);

  const signIn = useCallback(async (email, password) => {
    const authResult = await sendSignInRequest(email, password);
    if (authResult.isOk) {
      const profileResult = await fetchUserProfile(authResult.data.access_token);
      if (profileResult.isOk) {
        setUser(profileResult.data);
      } else {
        console.error('Failed to fetch user profile:', profileResult.message);
        // Handle profile fetching error (e.g., show notification)
      }
    }
    return authResult;
  }, [fetchUserProfile]);

  const signOut = useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    (async function () {
      setLoading(false);
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
