
export const populationByRegions = [{
    region: 'Amhara',
    val: 100,
  }, {
    region: 'Oromia',
    val: 10,
  }, {
    region: 'Adiss Abeba',
    val: 20,
  }, {
    region: 'South',
    val: 5,
  }, {
    region: 'Tigray',
    val: 7,
  }, {
    region: 'Benishangul',
    val: 150,
  }];
  