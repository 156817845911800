import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import Form, { 
  SimpleItem, 
  GroupItem,
  ButtonItem,
  TabbedItem,
  Tab,
  TabPanelOptions,
  ButtonOptions
} from 'devextreme-react/form';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import serverUrl from '../../url'

const EditMember = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const navigate = useNavigate();
  const [member, setMember] = useState({
    የአባላት_ሙሉ_ስም: '',
    ስልክ: '',
    ዜግነት: '',
    ክልል: '',
    ከተማ: '',
    ወረዳ: '',
    ቀበሌ: '',
    አክ_ድርሻ: '',
    ሸርብዛት: '',
    ወ_መ: '',
    ሌላ_ስልክ: '',
    ተወካይ: '',
    የተከፈለ: '',
    ምርመራ: ''
  });

  useEffect(() => {
    axios.get(`${serverUrl}/members/${id}`)
      .then(res => {
        setMember(res.data); // Set member state with fetched data
      })
      .catch(error => {
        console.error('Error fetching member data:', error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`http://localhost:8081/members/${id}`, member)
      .then(res => {
        console.log('Member updated successfully:', res.data);
        navigate('/members2');
      })
      .catch(error => {
        console.error('Error updating member:', error);
      });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.editorOptions;
    setMember(prevMember => ({
      ...prevMember,
      [name]: value
    }));
  };

  return (
    <div className="content-block dx-card responsive-paddings">
      <form onSubmit={handleSubmit}>
        <Form
          id="form"
          formData={member}
          labelLocation="top"
          showColonAfterLabel={true}
        >
          <GroupItem colCount={2}>
            <GroupItem caption="Edit Member Information">
              <SimpleItem 
                dataField="የአባላት_ሙሉ_ስም" 
                isRequired={true} 
                editorOptions={{ placeholder: 'Enter የአባላት_ሙሉ_ስም' }}
                onChange={handleFieldChange}
              />
              <SimpleItem 
                dataField="ስልክ" 
                editorOptions={{ placeholder: 'Enter ስልክ' }}
                onChange={handleFieldChange}
              />
              <SimpleItem 
                dataField="ክልል" 
                label={{ visible: true }} 
                editorOptions={{ placeholder: 'Enter ክልል' }} 
              />
              <SimpleItem 
                dataField="ዜግነት" 
                label={{ visible: true }} 
                editorOptions={{ placeholder: 'Enter ዜግነት' }} 
              />
              <SimpleItem 
                dataField="ከተማ" 
                label={{ visible: true }} 
                editorOptions={{ placeholder: 'Enter ከተማ' }} 
              />
              <SimpleItem 
                dataField="ወረዳ" 
                label={{ visible: true }} 
                editorOptions={{ placeholder: 'Enter ወረዳ' }} 
              />
            </GroupItem>
            <GroupItem caption="Personal Information">
              <TabbedItem>
                <TabPanelOptions height={260} />
                <Tab title="Contacts">
                  <SimpleItem 
                    dataField="አክ_ድርሻ" 
                    label={{ visible: true }} 
                    editorOptions={{ 
                        placeholder: 'Enter አክ_ድርሻ',
                        disabled: true
                    }} 
                
                  />
                  <SimpleItem 
                    dataField="ሸርብዛት" 
                    label={{ visible: true }} 
                    editorOptions={{ placeholder: 'Enter ሸርብዛት' }} 
                  />
                  <SimpleItem 
                    dataField="ወ_መ" 
                    label={{ visible: true }} 
                    editorOptions={{ placeholder: 'Enter ወ_መ' }} 
                  />
                  <SimpleItem 
                    dataField="ሌላ_ስልክ" 
                    label={{ visible: true }} 
                    editorOptions={{ placeholder: 'Enter ሌላ_ስልክ' }} 
                  />
                  <SimpleItem 
                    dataField="ተወካይ" 
                    label={{ visible: true }} 
                    editorOptions={{ placeholder: 'Enter ተወካይ' }} 
                  />
                </Tab>
              </TabbedItem>
            </GroupItem>
          </GroupItem>
          <ButtonItem horizontalAlignment="center">
            <ButtonOptions 
              text="Submit the Form"
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
    </div>
  );
};

export default EditMember;
