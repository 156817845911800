//import defaultUser from '../utils/default-user';
import axios from 'axios';
import serverUrl from '../url'

export async function signIn(email, password) {
  console.log("inside signIn "+email)
  try {
    const response = await axios.post(`${serverUrl}login`, { email, password });
    if (response.status === 200) {
      return {
        isOk: true,
        data: response.data
      };
    }
  } catch (error) {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}
export async function fetchUserProfile(accessToken) {
  try {
    const response = await axios.get(`${serverUrl}profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    if (response.status === 200) {
      return {
        isOk: true,
        data: response.data
      };
    }
  } catch (error) {
    return {
      isOk: false,
      message: "Failed to fetch user profile"
    };
  }
}
export async function getUser() {
  try {
    //http://localhost:8081/users
    const response = await axios.get('/api/auth/user');
    if (response.status === 200) {
      return {
        isOk: true,
        data: response.data
      };
    }
  } catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(name, email, password) {
  try {
    const response = await axios.post('/api/auth/register', { data: { attributes: { name, email, password } } });
    if (response.status === 201) {
      return {
        isOk: true
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function resetPassword(email) {
  try {
    const response = await axios.post('/api/auth/password-forgot', { data: { attributes: { email } } });
    if (response.status === 200) {
      return {
        isOk: true
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    const response = await axios.post('/api/auth/password-reset', { email, recoveryCode });
    if (response.status === 200) {
      return {
        isOk: true
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to change password"
    };
  }
}
